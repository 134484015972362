import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { regularPrices, airportPrices } from '../utils/companyData';
import { styled } from '@mui/system';

const PlanCard = styled(Paper)({
  padding: '2rem',
  textAlign: 'center',
  color: '#333', // Fallback to a default color
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    color: '#111'
  },
  minHeight: '320px',
});

const Feature = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '0.5rem',
  color: 'black'
});

const Prices = () => {
  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Roboto Slab', fontOpticalSizing: 'auto', fontWeight: 'normal' }}>
        Avem următoarea listă de prețuri.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <PlanCard elevation={3}>
            <Typography variant="button" >Transport Regular </Typography>
            <hr style={{ border: '1px solid #333' }} />
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Pentru cursele locale și externe avem următoarele tarife:
            </Typography>
            <hr style={{ border: '1px solid #333' }} />
            <Feature>
              <Typography variant="body2">✔ Pornire:{regularPrices.kmStart}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ 1 km interior: {regularPrices.kmInside} </Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ 1 km exterior: {regularPrices.kmOutside}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ 1 km pe timp de zi: {regularPrices.kmOnTheDay}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ 1 km pe timp de noapte: {regularPrices.kmOnTheNight}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ Staționare: {regularPrices.stationary}</Typography>
            </Feature>
          </PlanCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlanCard elevation={3} style={{ backgroundColor: '#FFD700' }}>
            <Typography variant="button">Transfer Aeroport</Typography>
            <hr style={{ border: '1px solid #333' }} />
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Pentru curse <span style={{ color: '#000', fontWeight: 'bold' }}>spre</span> și <span style={{ color: '#000', fontWeight: 'bold' }}>de la </span> aeroport avem:
            </Typography>
            <hr style={{ border: '1px solid #333' }} />
            <Feature >
              <Typography variant="body2">✔ Aeroport Suceava : {airportPrices.suceavaAeroportPrice}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ Aeroport Iași  : {airportPrices.iasiAeroportPrice}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ Aeroport Bacău : {airportPrices.bacauAeroportPrice}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ Aeroport Cluj : {airportPrices.clujAeroportPrice}</Typography>
            </Feature>
            <Feature>
              <Typography variant="body2">✔ Aeroport București : {airportPrices.bucurestiAroportPrice}</Typography>
            </Feature>
          </PlanCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlanCard elevation={3}>
            <Typography variant="button">Altă distanță</Typography>
            <hr style={{ border: '1px solid #333' }} />
            <Typography variant="body2">
              Și pentru oricare altă destinație, prețul este<span style={{ color: '#000', fontWeight: 'bold' }}> distanța în km (Google Maps) × 3,5 Ron = prețul final</span> al cursei.
            </Typography>
            <Typography variant="body2">
              Pentru cursele de la aeroport care au întârziere, așteptarea gratuită de  <span style={{ color: '#000', fontWeight: 'bold' }}>30 de minute</span> este inclusă în prețul final al cursei.
            </Typography>
            <hr style={{ border: '1px solid #333' }} />
          </PlanCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Prices;
