import { createContext, useState } from "react";

export const AppContext = createContext({
  isAdmin: false,
  setIsAdmin: () => { },
  isLoginOpen: false,
  setIsLoginOpen: () => { },
  showLoginModal: () => { },
});

export default function AppProvider(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const ContextValues = {
    isAdmin,
    setIsAdmin,
    isLoginOpen,
    setIsLoginOpen,
    showLoginModal: () => setIsLoginOpen(!isLoginOpen)
  };

  return (
    <AppContext.Provider value={ContextValues}>
      {props.children}
    </AppContext.Provider>
  );
}

