const clientInfo = {
  phone: '0755934928',
  email1: 'botosanitaxirapid@gmail.com',
  email2: 'botosanitaxistars@gmail.com',
  phoneAction: () => window.open(`tel:${clientInfo.phone}`, '_blank'),
  whatsappAction: () => window.open('https://api.whatsapp.com/send/?phone=40755934928&text&type=phone_number&app_absent=0', '_blank'),
};
const airportPrices = {
  suceavaAeroportPrice: "150 Ron",
  iasiAeroportPrice: "400 Ron",
  bacauAeroportPrice: "550 Ron",
  clujAeroportPrice: "240 Euro",
  bucurestiAroportPrice: "300 Euro",

};
const regularPrices = {
  kmPrice: "3.5 Ron",
  kmStart: "3.5 Ron",
  kmInside: "3.5 Ron",
  kmOutside: "3.5 Ron",
  kmOnTheNight: "3.5 Ron",
  kmOnTheDay: "3.5 Ron",
  stationary: "35 Ron/h"
};



export { clientInfo, airportPrices, regularPrices };