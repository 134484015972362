import React from "react";
import { Grid } from "@mui/material";

const Map = () => {
  return (
    <Grid
      container
      sx={{
        width: { xs: '100%', sm: '80%', md: '100%' },
        height: { xs: '300px', sm: '300px', md: '300px' }, // Adjust height according to screen size
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: 2,
        position: 'relative', // Allows for proper scaling and positioning
      }}
      justifyContent="center"
      alignItems="center"
    >
      <iframe
        title="Location Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2853.444655116695!2d26.653821!3d47.748292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734ebd77476bc5d:0xd437ecf3dd964fb3!2sStrada+Grivi%C8%99a+7,+Boto%C8%99ani,+Romania!5e0!3m2!1sro!2sro!4v1663601133459!5m2!1sro!2sro"
        width="100%"
        height="100%"
        style={{
          border: 0,
          borderRadius: 'inherit',
          minHeight: '250px', // Ensure minimum height
          display: 'inline-block',
        }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </Grid>
  );
};

export default Map;

