import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { clientInfo } from '../utils/companyData';
export default function About() {
  const { phone, phoneAction } = clientInfo;
  const needATaxi = process.env.PUBLIC_URL + '/images/tag2.png';
  const needATaxiNonStop = process.env.PUBLIC_URL + '/images/jet.png';
  return (
    <Box sx={{ padding: '20px', backgroundColor: '#1b1b1b', color: '#fff', borderRadius: '8px' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left side - Text and Stats */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            SIGURANȚĂ <span style={{ color: '#fbc02d' }}>EFICIENȚĂ</span> PROFESIONALISM
          </Typography>
          <Typography variant="body2" paragraph>
            Cu noi te afli în siguranță indiferent de traficul urban, fiind condus de profesioniști pe drumul cel mai scurt către destinația dorită, în timp util și confortul dorit.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Transport</Typography>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">Transfer aeroport Botoșani - Suceava</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">Transfer aeroport Botoșani - Iași</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">Transfer aeroport Botoșani – Cluj și orice județ limitrof și din țară</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Right side - Download options */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Box sx={{
                backgroundColor: '#333', padding: '20px', borderRadius: '8px', textAlign: 'center', minHeight: '255px', height: '100%', transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',

                },
              }} >
                <Box sx={{ marginTop: '10px' }} maxWidth={'500px'}>
                  <Typography variant='h6'> Nu ezita să apelezi:</Typography>
                  <span style={{ color: '#fbc02d', fontWeight: 'bolder' }}> {phone}</span>
                  <img src={needATaxiNonStop} alt="need a taxi" style={{ width: '100%' }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{
                backgroundColor: '#333', padding: '20px', borderRadius: '8px', textAlign: 'center', minHeight: '255px', height: '100%', transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  color: '#111'
                },
              }} maxWidth={'500px'}>
                <Box>
                  <img src={needATaxi} alt="need a taxi" style={{ width: '100%' }} />
                </Box>
                <Button variant="contained" sx={{
                  marginTop: '10px', backgroundColor: '#fbc02d', color: '#000', '&:hover': {
                    border: '1px solid gray',
                    backgroundColor: '#fbc02d',
                    color: 'white',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      color: '#111'
                    },
                  },
                }} onClick={phoneAction} >Comandă acum: {phone}</Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
}

