import * as React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import { clientInfo } from '../utils/companyData';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { keyframes } from '@mui/system'; // Import keyframes from MUI

/**
 * Renders a fade-in instant call component that appears at the bottom left of the screen when the user scrolls.
 *
 * @param {Object} props - The props object containing the children and window properties.
 * @param {ReactNode} props.children - The content to be displayed inside the component.
 * @param {Object} props.window - The window object for accessing the scroll position.
 * @return {JSX.Element} The rendered fade-in instant call component.
 */
function InstantCall(props) {
  const { phoneAction } = clientInfo;
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger}>
      <Box
        onClick={phoneAction}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          cursor: 'pointer',
          zIndex: 1300
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

/**
 * A React component that renders a "Back to Top" button with a ringing phone effect.
 *
 * @param {object} props - The properties passed to the component.
 * @return {JSX.Element} A JSX element representing the "Back to Top" button.
 */
export default function BackToTop(props) {
  // Define the ringing animation using keyframes
  const ringAnimation = keyframes`
    0% { transform: rotate(0deg); }
    10% { transform: rotate(15deg); }
    20% { transform: rotate(-10deg); }
    30% { transform: rotate(15deg); }
    40% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
    60% { transform: rotate(-5deg); }
    70% { transform: rotate(5deg); }
    80% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  `;

  return (
    <React.Fragment>
      <InstantCall {...props}>
        <Fab size="small" aria-label="scroll back to top" sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: '#8B0000' } }}>
          <PhoneInTalkIcon
            sx={{
              color: 'white',
              animation: `${ringAnimation} 1.5s infinite`, // Apply the animation
            }}
          />
        </Fab>
      </InstantCall>
    </React.Fragment>
  );
}

InstantCall.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
