import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
export default function IpTable({ visitors, setSelectedVisitor }) {

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: 'auto', marginTop: '20px' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Adresă Ip</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Regiune</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Vizitări</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Acţiune</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map((row, index) => (
            <TableRow
              key={row._id}
              sx={{
                backgroundColor: index % 2 === 0 ? '#e5e5e5' : '#cccccc',
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell component="th" scope="row" sx={{ padding: '10px' }}>
                {row.ip}
              </TableCell>
              <TableCell align="right" sx={{ padding: '10px' }}>{row.state}</TableCell>
              <TableCell align="right" sx={{ padding: '10px' }}>{row.visits}</TableCell>
              <TableCell align="right" sx={{ padding: '1px' }}>
                <Button size="small" color='inherit' startIcon={<ArrowOutwardIcon />} onClick={() => setSelectedVisitor(row._id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
