import React, { useState } from "react";
import { Grid, Typography, Box, Button, TextField } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { clientInfo } from "../utils/companyData";
import Map from "./Map";

const ContactInfo = () => {
  const [userPhone, setUserPhone] = useState('');
  const handleContactMe = (e) => {
    let email = e.target.value;
    setUserPhone('');
  };
  const { phone, email1, email2, phoneAction, whatsappAction } = clientInfo;

  return (
    <Box sx={{ width: "100%" }}>
      {/* Top Section */}
      <Box
        sx={{
          backgroundColor: "#FFD700",
          textAlign: "center",
          py: 4,
          borderRadius: '10px',
          width: { xs: '90%', sm: '80%', md: '60%' },
          mx: "auto",
          position: "relative",
          bottom: 0,
          boxShadow: 3,
        }}
      >
        <Typography variant="inherit" sx={{ mb: 2 }}>
          Nu ezita să apelezi <b>{phone}</b>. Sunem alături de toate călătoriile tale.
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } }}>
          Sună acum:
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                px: 3,
                py: 1.5,
                fontSize: { xs: "0.75rem", sm: "1rem" },
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.15)',
                  backgroundColor: 'red',
                  border: '1px solid gray',
                }
              }}
              startIcon={<LocalPhoneIcon />}
              onClick={phoneAction}
            >
              {phone}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                px: 3,
                py: 1.5,
                fontSize: { xs: "0.75rem", sm: "1rem" },
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.15)',
                  backgroundColor: '#25D366',
                }
              }}
              startIcon={<WhatsAppIcon />}
              onClick={whatsappAction}
            >
              WhatsApp
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          backgroundColor: "#333",
          color: "#fff",
          py: 7,
          mt: 4,
          padding: { xs: "20px", sm: "30px", md: "50px 100px" }, // Adjust padding as needed
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          {/* Company Links */}
          <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
            <Typography variant="h6" component="h4" sx={{ mb: 2 }}>
              Locația
            </Typography>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
              <Map />
            </Box>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
            <Typography variant="h6" component="h4" sx={{ mb: 2 }}>
              Contact
            </Typography>
            <Typography variant="body1">Mobil:</Typography>
            <ul>
              <li><Typography variant="body2">{phone}</Typography></li>
            </ul>
            <br />
            <Typography variant="h6" component="h4">Email:</Typography>
            <ul>
              <li><Typography variant="body2">{email1}</Typography></li>
              <li><Typography variant="body2">{email2}</Typography></li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactInfo;
