import React from "react";
import { Grid, Typography, Box, Button, BottomNavigationAction } from "@mui/material";
import styles from './styles.module.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { clientInfo } from "../utils/companyData";

import Logo from './Logo';
export default function ContactBanner() {
  const { phone, phoneAction, whatsappAction } = clientInfo;
  const carIllustration = process.env.PUBLIC_URL + '/images/flaingJet.png';
  return (
    <Box className={styles.boxInfo} sx={{ padding: '40px', maxWidth: '1200px', margin: '0 auto' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <section id="top">
            <Typography variant="subtitle1" gutterBottom>
              Solicită o cursă de la:</Typography>
          </section>
          <Grid item xs={12} sm={12} md={2} lg={1} container justifyContent="center" alignItems="center">
            {/* Display the Logo for larger screens */}
            <BottomNavigationAction
              icon={<Logo />}
              sx={{ display: { xs: 'block', lg: 'none', md: 'none', sm: 'none' } }}
            />
          </Grid>
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontFamily: 'Roboto Slab', fontOpticalSizing: 'auto', fontWeight: 'normal', display: { xs: 'none', lg: 'block', md: 'block', sm: 'block' } }}>
            Taxi Botoșani
          </Typography>
          <Box sx={{ display: 'flex', gap: { xs: '5px', lg: '50px' }, marginBottom: '20px', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              sx={{
                color: 'black',
                backgroundColor: 'red',
                border: '1px solid black',
                minWidth: '150px',
                minHeight: '50px',
                fontWeight: 'bold',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid gray',
                  backgroundColor: 'red',
                },
              }}
              onClick={phoneAction}>
              <LocalPhoneIcon sx={{ fontSize: { xs: 25, sm: 40 } }} />
              {phone}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{
                color: 'black',
                backgroundColor: '#25D366',
                border: '1px solid green',
                fontWeight: 'bold',
                minWidth: '150px',
                minHeight: '50px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid green',
                  backgroundColor: '#25D366',
                  color: 'black',
                },
              }}
              onClick={whatsappAction}
            >
              <WhatsAppIcon sx={{ fontSize: { xs: 25, sm: 40 } }} />
              &nbsp;
              <Typography sx={{ color: 'inherit', textTransform: 'none' }}>WhatsApp</Typography>
            </Button>
          </Box>
          <Typography variant="body1" paragraph>
            TAXI BOTOȘANI STARS și TAXI BOTOȘANI RAPID NON-STOP
          </Typography>

          <Typography variant="body2" paragraph>
            Oferim transport autorizat de persoane în regim de taxi, inclusiv transferuri la aeroport, gară și către destinații din județele limitrofe sau din țară. Acceptăm comenzi anticipate și oferim servicii la prețuri accesibile, adaptate nevoilor dumneavoastră.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            {/* //NOTE - THINK ABOUT HOW TO STYLE THIS and how to fix this */}
            <Typography variant="body2" >
              Transfer aeroport Botoșani - Suceava | Transfer aeroport Botoșani - Iași | Transfer aeroport Botoșani – Cluj și orice județ limitrof și din țară | Rezervări transfer aeroport
            </Typography>
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={carIllustration} alt="Car Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

