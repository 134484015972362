import React, { useContext } from 'react';
import { BottomNavigation, Grid, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from '../context/appContext';
import Logo from './Logo';
import styles from './styles.module.css';

/**
 * A functional component that renders the navigation header of the application.
 *
 * It provides a responsive navigation menu with a hamburger icon for smaller screens
 * and a bottom navigation bar for larger screens. The component also includes a drawer
 * that slides in from the left side of the screen when the hamburger icon is clicked.
 *
 * @return {JSX.Element} The JSX element representing the navigation header.
 */
export default function HeaderNavigation() {
  const { showLoginModal } = useContext(AppContext);
  const [menuItemValue, setValue] = React.useState('home');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  /**
   * Toggles the state of the drawer based on the provided open state.
   *
   * @param {boolean} open - The desired state of the drawer.
   * @return {function} A function that handles the toggle event.
   */
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  /**
 * Handles a menu click event.
 *
 * Updates the current menu item value and performs actions based on the selected section.
 *
 * @param {string} section - The selected menu section.
 * @return {void}
 */
  const handleMenuClick = (section) => {
    setValue(section);
    if (section === 'login') {
      showLoginModal();
      return;
    }
    scrollToSection(section);

  };
  /**
 * Handles menu click event from the drawer menu.
 *
 * Updates the current menu item value and performs actions based on the selected section.
 * If the selected section is 'login', it shows the login modal and closes the drawer.
 * Otherwise, it scrolls to the selected section and closes the drawer.
 *
 * @param {string} section - the selected menu section
 * @return {void}
 */
  const handleDrawerMenuClick = (section) => {
    setValue(section);
    if (section === 'login') {
      showLoginModal();
      setDrawerOpen(false);
      return;
    }
    scrollToSection(section);
    setDrawerOpen(false);

  };

  const menuItems = [
    {
      label: 'Acasă', value: 'home', icon: <HomeIcon />,
    },
    { label: 'Despre', value: 'about', icon: <InfoIcon /> },
    { label: 'Prețuri', value: 'prices', icon: <AttachMoneyIcon /> },
    { label: 'Servicii', value: 'services', icon: <LocalTaxiIcon /> },
    { label: 'Clienți', value: 'clients', icon: <StarBorderPurple500Icon /> },
    { label: 'Contact', value: 'contact', icon: <ContactPhoneIcon /> },
    { label: 'Login', value: 'login', icon: <LoginIcon /> },
  ];

  /**
   * Scrolls the window to a specific section identified by the given sectionId.
   *
   * @param {string} sectionId - The id of the section to scroll to.
   * @return {void}
   */
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };
  return (
    <Grid container spacing={2} className={`${styles.alignCenter} ${styles.sticky}`}>
      {/* Hamburger Menu Icon on the left side */}
      <Grid item xs={6} sm={6} md={2} lg={1}>
        <IconButton
          sx={{ color: 'black', paddingLeft: '25px', display: { xs: 'block', md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        {/* Display the Logo for larger screens */}
        <BottomNavigationAction
          icon={<Logo />}
          onClick={() => handleMenuClick('home')}
          sx={{
            display: { xs: 'none', lg: 'block' },
          }
          }
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6}>
        {/* Display BottomNavigation on medium and larger screens */}
        <BottomNavigation
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: '100%',
          }}
          value={menuItemValue}
        >
          {menuItems.map((item) => (
            <BottomNavigationAction
              key={item.value}
              label={item.label}
              value={item.value}
              showLabel
              icon={item.icon}
              onClick={() => handleMenuClick(item.value)}
            />
          ))}
        </BottomNavigation>
      </Grid>
      {/* Drawer for the menu items */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        value={menuItemValue}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem
              key={item.value}
              selected={menuItemValue === item.value}
              onClick={() => {
                handleDrawerMenuClick(item.value);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Grid>
  );
}
