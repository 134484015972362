import axios from 'axios';

export const apiGet = async (url) => {
  const { data: responses } = await axios.get(url);
  return responses;
};
export const apiPost = async (url, payload) => {
  const { data: responses } = await axios.post(url, payload);
  return responses;
};

