
export const Urls = {
  isAdmin: "/isAdmin",
  saveAdmin: "/saveAdmin",
  getVisitors: "/getVisitors",
  addAVisitor: "/addAVisitor",
};
export const baseUrl = process.env.BASE_URL || 'http://localhost:5000';




