import React, { useContext, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import IpTable from './IpTable';
import { AppContext } from '../context/appContext';
import { apiGet } from '../api/api';
import { Urls, baseUrl } from '../api/urls';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DetailedModal from './DetailedModal';
/**
 * A functional component that renders an admin modal.
 * It fetches a list of visitors from the server and displays them in a table.
 * The modal also includes a refresh button to update the visitor list.
 *
 * @return {JSX.Element} The rendered admin modal component
 */
const AdminModal = () => {
  const { isAdmin, setIsAdmin } = useContext(AppContext);
  const [visitors, setVisitors] = useState([]);
  const [visitor, setVisitor] = useState(null);
  const [showDetails, setShowDetails] = useState(false);


  const handleClose = () => setIsAdmin(false);
  useEffect(() => {

    getVisitors();
  }, []);

  /**
 * Retrieves a list of visitors from the server and updates the component's state.
 *
 * @return {void}
 */
  const getVisitors = async () => {
    const response = await apiGet(baseUrl + Urls.getVisitors);
    setVisitors(response.visitors);
  };
  const setSelectedVisitor = (visitorId) => {
    if (visitorId !== null) {
      setVisitor(visitors.find((visitor) => visitor._id === visitorId));
      setShowDetails(true);
    }
  };
  return (
    <div>
      <Modal
        open={isAdmin}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: 'center' }}
          >
            Bine ai venit pe pagina de administrare!
          </Typography>
          <IpTable visitors={visitors} setSelectedVisitor={setSelectedVisitor} />
          <br />
          <Button
            variant="outlined"
            color='success'
            sx={{ width: '100%', height: '100%' }}
            startIcon={<ReplayCircleFilledIcon />}
            onClick={getVisitors}
          >
            Refresh
          </Button>
        </Box>
      </Modal>
      <DetailedModal visitor={visitor} showDetails={showDetails} setShowDetails={setShowDetails} />
    </div>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export default AdminModal;
