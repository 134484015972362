import './App.css';
import About from './components/About';
import Services from './components/Services';
import ContactBanner from './components/ContactBanner';
import HeaderNavigation from './components/HeaderNavigation';
import AppProvider from './context/appContext';
import Clients from './components/Clients';
import ContactInfo from './components/ContactInfo';
import Prices from './components/Prices';
import ScrollTop from './components/ScrollTop';
import LoginModal from './components/LoginModal';
import AdminModal from './components/AdminModal';
import InstantCall from './components/InstantCall';
import { useEffect } from 'react';
import { recordVisitorsActivity } from './utils/recordVisitorsActivity';

function App() {
  useEffect(() => {
    recordVisitorsActivity();
  }, []);
  return (
    <AppProvider>
      <HeaderNavigation />
      <section id='home'>
        <ContactBanner />
      </section>
      <section id='about'>
        <About />
      </section>
      <section id='prices'>
        <Prices />
      </section>
      <section id='services'>
        <Services />
      </section>
      <section id='clients'>
        <Clients />
      </section>
      <section id='contact'>
        <ContactInfo />
      </section>
      <LoginModal />
      <ScrollTop />
      <InstantCall />
      <AdminModal />
    </AppProvider>
  );
};

export default App;
