import React, { useState } from "react";
import { Grid, Typography, Avatar, Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const clientsData = [
  {
    name: "Ana Popescu",
    image: "https://example.com/ana.jpg",
    testimonial:
      "Serviciul de taxi a fost excelent! Mașina a fost curată și șoferul foarte amabil. Am ajuns la destinație rapid și în siguranță.",
    rating: 5,
  },
  {
    name: "Ion Ionescu",
    image: "https://example.com/ion.jpg",
    testimonial:
      "Am folosit serviciul de taxi de mai multe ori și sunt foarte mulțumit. Șoferii sunt punctuali și cursele sunt la prețuri corecte.",
    rating: 4,
  },
  {
    name: "Maria Georgescu",
    image: "https://example.com/maria.jpg",
    testimonial:
      "Am avut o experiență plăcută cu această companie de taxi. Șoferul a fost foarte profesionist și mi-a oferit o călătorie confortabilă.",
    rating: 5,
  },
  {
    name: "Alexandru Mihai",
    image: "https://example.com/alexandru.jpg",
    testimonial:
      "O experiență de taxi impecabilă. Șoferul a fost foarte politicos și m-a ajutat cu bagajele. Voi apela cu siguranță din nou!",
    rating: 5,
  },
  {
    name: "Elena Dumitru",
    image: "https://example.com/elena.jpg",
    testimonial:
      "Cursele sunt întotdeauna rapide și sigure. Siteul lor pentru comandat taxi este ușor de folosit și șoferii sunt mereu punctuali.",
    rating: 4,
  },
  {
    name: "Florin Vasilescu",
    image: "https://example.com/florin.jpg",
    testimonial:
      "Am avut o călătorie plăcută cu acest serviciu de taxi. Mașina a fost curată și călătoria foarte confortabilă. Voi apela din nou!",
    rating: 5,
  },
];


const Clients = () => {
  //NOTE - FIX this to have smooth animation
  const [currentClientIndex, setCurrentClientIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentClientIndex((prevIndex) =>
      prevIndex === 0 ? clientsData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentClientIndex((prevIndex) =>
      prevIndex === clientsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { name, image, testimonial, rating } = clientsData[currentClientIndex];

  return (
    <Box sx={{ borderRadius: '8px', height: 'auto' }} >
      <Box sx={{ marginTop: '10px' }}  >
        <Box sx={{ p: 4, textAlign: "center", backgroundColor: "#red", maxWidth: '600px', margin: '0 auto', borderRadius: '8px' }}>
          <Typography variant="h4" component="h2" gutterBottom textTransform={"capitalize"} textOverflow={"clip"} >
            Clienții nostri  mulțumiți
          </Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center" >
            <Grid item sx={{
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.20)',
              }
            }} >
              <IconButton onClick={handlePrevious}>
                <ArrowBackIosIcon fontSize="large" sx={{ color: "#b0bec5" }} />
              </IconButton>
            </Grid>
            <Grid item sx={{
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              }
            }}>
              <Avatar
                alt={name}
                src={image}
                sx={{ width: 80, height: 80, margin: "0 auto" }}
              />
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}  >
                {name}
              </Typography>

              <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
                {[...Array(rating)].map((_, index) => (
                  <span key={index} style={{ color: "#FFD700", fontSize: "20px" }}>
                    ★
                  </span>
                ))}
              </Box>
              <Typography
                variant="body1"
                sx={{ mt: 2, maxWidth: 400, margin: "0 auto" }}
              >
                {testimonial}
              </Typography>
            </Grid>
            <Grid item sx={{
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.20)',
              }
            }}>
              <IconButton onClick={handleNext}>
                <ArrowForwardIosIcon fontSize="large" sx={{ color: "#b0bec5" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>



  );
};

export default Clients;
