import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const DetailedModal = ({ visitor, showDetails, setShowDetails }) => {

  return (
    <div>
      <Modal
        open={showDetails}
        onClose={() => setShowDetails(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: 'center' }}
          >
            Detalii:
          </Typography>
          <br />
          <pre style={{ textAlign: 'left', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {JSON.stringify({
              ...visitor,
              _id: undefined, __v: undefined,
              date: visitor?.date ? new Intl.DateTimeFormat('ro-RO', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(visitor.date)) : 'N/A',
            }, null, 2)}
          </pre>
          <br />
          <Button
            variant="outlined"
            color='success'
            sx={{ width: '100%', height: 'auto' }}
            onClick={() => setShowDetails(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' }, // Responsive width for various screen sizes
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
  borderRadius: 3,
  overflowY: 'auto', // Ensures content scrolls on small screens if necessary
  maxHeight: '90vh', // Ensures modal doesn't overflow on small screens
};

export default DetailedModal;
